<template>
  <v-card-text>
    <v-fade-transition group leave-absolute>
      <v-progress-linear
        v-if="loading"
        key="loading"
        indeterminate
        color="primary"
      />
      <v-row v-else key="data">
        <v-col
          xl="2" lg="3" md="4" sm="6" cols="12"
          v-for="item in cashFlows"
          :key="item.id"
        >
          <v-responsive
            :aspect-ratio="$vuetify.breakpoint.smAndDown ? 6/2 : 1"
            style="overflow: visible"
            max-width="800px"
          >
            <v-sheet
              height="100%"
              rounded="sm"
              elevation="1"
              style="overflow: hidden;"
              class="d-flex flex-column"
            >
              <div class="d-flex justify-end primary darken-2">
                <v-tooltip bottom :open-delay="250">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="blue"
                      class="ml-4"
                      icon
                      v-on="on"
                      @click="$refs.cashFlowDialogForm.open(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar Controle de Caixa</span>
                </v-tooltip>
                <v-tooltip bottom :open-delay="250">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      color="error"
                      v-on="on"
                      @click="deleteCashFlow(item)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Deletar Controle de Caixa</span>
                </v-tooltip>
              </div>
              <v-card
                class="d-flex align-center justify-center pa-2 rounded-t-0 primary flex-grow-1"
                @click.left="item.loading = !$event.ctrlKey"
                :to="{
                  name: 'cash-flow',
                  params: {
                    cash_flow_id: item.id,
                    name: item.name,
                  },
                }"
              >
                <span
                  v-if="!item.loading"
                  class="white--text text-md-h5 text-sm-h6 text-center"
                >
                  <strong>{{ item.name }}</strong>
                </span>
                <v-progress-circular v-else indeterminate color="white" />
              </v-card>
            </v-sheet>
          </v-responsive>
        </v-col>
        <v-col xl="2" lg="3" md="4" sm="6" cols="12">
          <v-responsive
            :aspect-ratio="$vuetify.breakpoint.smAndDown ? 6/2 : 1"
            style="overflow: visible"
            max-width="800px"
          >
            <v-card
              elevation="1"
              color="grey lighten-4"
              class="d-flex flex-column align-center justify-center pa-2"
              rounded="sm"
              height="100%"
              style="border: 1px solid #006870 !important"
              @click="$refs.cashFlowDialogForm.open()"
            >
              <v-icon large>mdi-plus</v-icon>
              NOVO CONTROLE
            </v-card>
          </v-responsive>
        </v-col>
      </v-row>
    </v-fade-transition>
    <NewAndEditCashFlowDialog
      ref="cashFlowDialogForm"
      @updated="loadCashFlows()"
    />
  </v-card-text>
</template>

<script>
import NewAndEditCashFlowDialog from "@/components/cash_flow/NewAndEditCashFlowDialog.vue";

export default {
  name: "CashFlows",

  components: {
    NewAndEditCashFlowDialog,
  },

  data: () => ({
    loading: true,
    headers: [
      { text: "Nome", value: "name" },
      { text: "", value: "actions", sortable: false, align: "end" },
    ],
    cashFlows: [],
  }),

  methods: {
    reload(){
      this.loadCashFlows();
    },
    loadCashFlows() {
      this.loading = true;
      this.$axios
        .get("/cash-flows")
        .then((response) => {
          this.cashFlows = response.data.cash_flows.map((c) => ({
            ...c,
            loading: false,
          }));
        })
        .catch((error) => {
          this.$showMessage(
            "error",
            "Erro inesperado ao carregar controles de caixa"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteCashFlow(cashFlow) {
      this.$confirmDialog(
        "Você deseja deletar esse Controle de Caixa?",
        "O controle de caixa será apagado permanentemente.",
        () =>
          new Promise((resolve, reject) => {
            this.$axios
              .delete(`/cash-flow/${cashFlow.id}`)
              .then(() => {
                resolve();
                this.$showMessage("success", "Controle de caixa excluido");
                this.loadCashFlows();
              })
              .catch((error) => {
                reject();
                this.$showMessage("error", "Erro inesperado ao excluir");
              });
          })
      );
    },
    openCashFlow(cashFlow){
      cashFlow.loading = true;
      this.$router.push({
        name: 'cash-flow',
        params: {
          cash_flow_id: cashFlow.id,
          name: cashFlow.name
        },
      }).then(() => {
        cashFlow.loading = false;
      });
    },
  },
};
</script>
