var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-fade-transition',{attrs:{"group":"","leave-absolute":""}},[(_vm.loading)?_c('v-progress-linear',{key:"loading",attrs:{"indeterminate":"","color":"primary"}}):_c('v-row',{key:"data"},[_vm._l((_vm.cashFlows),function(item){return _c('v-col',{key:item.id,attrs:{"xl":"2","lg":"3","md":"4","sm":"6","cols":"12"}},[_c('v-responsive',{staticStyle:{"overflow":"visible"},attrs:{"aspect-ratio":_vm.$vuetify.breakpoint.smAndDown ? 6/2 : 1,"max-width":"800px"}},[_c('v-sheet',{staticClass:"d-flex flex-column",staticStyle:{"overflow":"hidden"},attrs:{"height":"100%","rounded":"sm","elevation":"1"}},[_c('div',{staticClass:"d-flex justify-end primary darken-2"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":250},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-4",attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.$refs.cashFlowDialogForm.open(item)}}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Controle de Caixa")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":250},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteCashFlow(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar Controle de Caixa")])])],1),_c('v-card',{staticClass:"d-flex align-center justify-center pa-2 rounded-t-0 primary flex-grow-1",attrs:{"to":{
                name: 'cash-flow',
                params: {
                  cash_flow_id: item.id,
                  name: item.name,
                },
              }},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }item.loading = !$event.ctrlKey}}},[(!item.loading)?_c('span',{staticClass:"white--text text-md-h5 text-sm-h6 text-center"},[_c('strong',[_vm._v(_vm._s(item.name))])]):_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}),_c('v-col',{attrs:{"xl":"2","lg":"3","md":"4","sm":"6","cols":"12"}},[_c('v-responsive',{staticStyle:{"overflow":"visible"},attrs:{"aspect-ratio":_vm.$vuetify.breakpoint.smAndDown ? 6/2 : 1,"max-width":"800px"}},[_c('v-card',{staticClass:"d-flex flex-column align-center justify-center pa-2",staticStyle:{"border":"1px solid #006870 !important"},attrs:{"elevation":"1","color":"grey lighten-4","rounded":"sm","height":"100%"},on:{"click":function($event){return _vm.$refs.cashFlowDialogForm.open()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-plus")]),_vm._v(" NOVO CONTROLE ")],1)],1)],1)],2)],1),_c('NewAndEditCashFlowDialog',{ref:"cashFlowDialogForm",on:{"updated":function($event){return _vm.loadCashFlows()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }